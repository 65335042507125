<template>
    <!-- 服务过程 -->
    <div class="service-evaluation-child">
        <div class="child-header">
            <h1>序号</h1>
            <h1>
                {{ currentData.name }}
            </h1>
            <h1>
                得分
            </h1>
        </div>
        <div class="service-evaluation-child-content" v-for="(item, index) in childList" :key="index">
            <div class="list-left">
                {{ index + 1 }}
            </div>
            <div class="section-select">
                <p v-if="index === 0">
                    <span class="el-icon-star-on"></span>
                    <span class="el-icon-star-on"></span>
                    <span class="el-icon-star-on"></span>
                    <span class="el-icon-star-on"></span>
                    <span class="el-icon-star-on"></span>
                </p>
                <p v-if="index === 1">
                    <span class="el-icon-star-on"></span>
                    <span class="el-icon-star-on"></span>
                    <span class="el-icon-star-on"></span>
                    <span class="el-icon-star-on"></span>
                </p>
                <p v-if="index === 2">
                    <span class="el-icon-star-on"></span>
                    <span class="el-icon-star-on"></span>
                    <span class="el-icon-star-on"></span>
                </p>
                <p v-if="index === 3">
                    <span class="el-icon-star-on"></span>
                    <span class="el-icon-star-on"></span>
                </p>
                <p v-if="index === 4">
                    <span class="el-icon-star-on"></span>
                </p>
            </div>
            <div class="list-right">
                <el-input
                    :disabled="viewOnly"
                    v-model="item.score"
                    @change="changeScore(item.score)"
                ></el-input>
            </div>
        </div>
        <div class="message-bottom-button" v-if="!viewOnly">
            <el-button type="primary" @click="saveList">
                保存
            </el-button>
            <el-button @click="closeWindow">
                取消
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            childList: [
                {
                    item_id: this.currentData.id,
                    credit_no: this.runTimeDate.AutoCodeValue,
                    item_code: this.currentData.code,
                    type: 3,
                    floor: '5',
                    upper: '0',
                    score: 0,
                },
                {
                    item_id: this.currentData.id,
                    credit_no: this.runTimeDate.AutoCodeValue,
                    item_code: this.currentData.code,
                    type: 3,
                    floor: '4',
                    upper: '0',
                    score: 0,
                },
                {
                    item_id: this.currentData.id,
                    credit_no: this.runTimeDate.AutoCodeValue,
                    item_code: this.currentData.code,
                    type: 3,
                    floor: '3',
                    upper: '0',
                    score: 0,
                },
                {
                    item_id: this.currentData.id,
                    credit_no: this.runTimeDate.AutoCodeValue,
                    item_code: this.currentData.code,
                    type: 3,
                    floor: '2',
                    upper: '0',
                    score: 0,
                },
                {
                    item_id: this.currentData.id,
                    credit_no: this.runTimeDate.AutoCodeValue,
                    item_code: this.currentData.code,
                    type: 3,
                    floor: '1',
                    upper: '0',
                    score: 0,
                },
            ],
            sectionData: '',
        };
    },
    props: {
        // 当前点击数据
        currentData: {
            type: Object,
        },
        // 当前期号
        runTimeDate: {
            type: Object,
        },
        // 是否只读
        viewOnly: {
            type: [String, Boolean],
        },
    },
    mounted() {
        this.getCreditScore();
    },
    methods: {
        // 获取已配置项
        getCreditScore() {
            this.$axios
                .get(`/interfaceApi/sale/credit_config/get_credit_score/${this.runTimeDate.AutoCodeValue}/${this.currentData.code}`)
                .then(res => {
                    if (res.length !== 0) {
                        this.childList = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        changeScore(score) {
            if (score > 10 || score < 1) {
                this.$message.error('得分必须在1-10之前');
            }
        },
        saveList() {
            const listA = this.childList.filter(item => item.score < 1 || item.score > 10);
            if (listA.length > 0 ) {
                this.$message.error('得分必须在1-10之间');
            } else {
                this.$emit('saveList', this.childList, 'Star');
            }
        },
        closeWindow() {
            this.$emit('closeWindow');
        },
    },
};

</script>
<style lang='stylus'>
.service-evaluation-child
    height 100%
    position relative
    .child-header
        width 100%
        display flex
        height .45rem
        h1
            flex 1
            display flex
            align-items center
            justify-content center
            border-bottom 1px solid #ccc
            border-right 1px solid #ccc
            background #d9e2f3
            color #092d8e
            &:nth-child(2)
                flex 2
            &:last-child
                border-right none
    .service-evaluation-child-content
        display flex
        width 100%
        height .45rem
        border-bottom 1px solid #ccc
        align-items center
        justify-content center
        .list-left
            flex 1
            height 100%
            text-align center
            line-height .45rem
            border-right 1px solid #ccc
        .section-select
            flex 2
            display flex
            align-items center
            p
                display flex
                span
                    background: linear-gradient(to bottom, #FFFFFF, #FF9900);
                    -webkit-background-clip: text;
                    color: transparent;
                    font-size .34rem
                    margin-left .2rem
        .list-right
            flex 1
            display flex
            align-items center
            justify-content center
            border-left 1px solid #ccc
            .el-input
                width .6rem
                height .45rem
                display flex
                align-items center
                justify-content center
                margin-right .2rem
                input
                    height .28rem
                    line-height .28rem
                    font-size .16rem
                    text-align center
                    padding 0
    .message-bottom-button
        width 100%
        display flex
        justify-content center
        margin .2rem 0
        position absolute
        bottom 0
        left 0
        width 100%
        button
            width 2.5rem
            &:last-child
                margin-left 1rem
</style>